<template>
  <v-select
    :label='$t("model.name.teacher")'
    class="white"
    :items="data"
    item-text="User.name"
    item-value="User.accountId"
    v-model="inputVal"
    :loading="api.isLoading"
  ></v-select>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: [],
  data: () => ({
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    data: [],
    code: null,
    item: null,
    value:null,
  }),
  created() {
    //BOC:[api]
    this.api.method = "get";

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.data = resp;
      const adminTeacher = this.$_.find(resp, (t) => {
        return this.$_.isEqual(t.User.accountId, t.Institution.accountId);
      });
      this.value = adminTeacher.User.accountId;
      this.$emit("input", this.value);
    };
    //EOC
  },
  computed: {
    ...mapState({
      school: (state) => state.school.data,
    }),
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    fetch() {
      this.api.url =`${this.$api.servers.sso}/api/v1/en/moderator/institution/${this.$route.params.parentId}/teacher`
      this.$api.fetch(this.api);
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>
